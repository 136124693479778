<template>
  <div
    class="my-6"
    style="max-width: 100%"
    >
    <div
      class="text-center text-h5 font-weight-medium secondary--text"
      >
      <v-icon
        x-large
        color="secondary"
        >
        mdi-storefront
      </v-icon>
    </div>

    <div
      class="text-center text-h5 font-weight-medium secondary--text"
      >
      Tiendas destacadas
    </div>

    <div
      class="mb-6 secondary mx-auto"
      style="height: 4px; width: 100px"
      >
    </div>

    <div
      class="my-6"
      >
      <v-slide-group
        >
        <v-slide-item
          v-for="store in stores"
          >
          <v-card
            outlined
            class="rounded-lg"
            height="200"
            width="180"
            :to="{ name: 'profile', params: { slug: store.profile.slug }}"
            >
            <v-card-text
              class="d-flex flex-column align-center justify-center fill-height"
              >
              <v-avatar
                size="100"
                :color="store.profile.picture ? '' : 'primary'"
                >
                <v-img
                  v-if="store.profile.picture"
                  :src="url + store.profile.picture.url"
                  ></v-img>

                <v-icon
                  v-else
                  color="white"
                  x-large
                  >
                  mdi-storefront
                </v-icon>
              </v-avatar>

              <div
                class="store-card-box body-1 black--text mt-2 font-weight-medium primary--text"
                style="max-width: 100%"
                >
                {{ store.name }}
              </div>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { RandomStores } from '@/graphql/queries/fairs/fairs'

export default {
  data: () => ({
    stores: []
  }),

  props: {
    fair: {
      required: true,
      type: Object
    }
  },

  created () {
    this.fetchStores()
  },

  computed: {
    ...mapGetters(['url'])
  },

  methods: {
    fetchStores () {
      this.$apollo.query({
        query: RandomStores,
        variables: {
          id: this.fair.id
        }
      }).then ( res => {
        this.stores = res.data.randomStoresInFair
      })
    }
  },
}
</script>

<style scoped>
>>> .v-slide-group__content {
  gap: 8px !important
}
</style>
